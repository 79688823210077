import { useState } from "react";

import { UserFeedback } from "../../../api/generated/backend";
import { SubmitFeedback } from "../../../api/generated/backend/models/SubmitFeedback";

const MAXIMUM_STAR_RATING = 5;
export const RatingUserFeedback: React.FC<{
  feedback: UserFeedback;
  setFeedback: (submitFeedback: SubmitFeedback | undefined) => void;
}> = ({ feedback, setFeedback }) => {
  const [rating, setRating] = useState(1);

  return (
    <div className="flex flex-col items-center gap-1">
      <div className="rating rating-lg">
        {Array.from(new Array(MAXIMUM_STAR_RATING + 1)).map((_, idx) => (
          <input
            key={idx}
            type="radio"
            className={`mask mask-star-2 bg-primary ${
              idx === 0 && "rating-hidden pointer-events-none"
            }`}
            checked={rating === idx + 1}
            onChange={() => {
              setRating(idx + 1);
              setFeedback({
                ...feedback,
                details: `${String(idx)}/${MAXIMUM_STAR_RATING}`,
              });
            }}
          />
        ))}
      </div>
    </div>
  );
};
