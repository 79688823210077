/* tslint:disable */
/* eslint-disable */
/**
 * mama health backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface LastActiveTenant
 */
export interface LastActiveTenant {
    /**
     * 
     * @type {string}
     * @memberof LastActiveTenant
     */
    disease: LastActiveTenantDiseaseEnum;
    /**
     * 
     * @type {string}
     * @memberof LastActiveTenant
     */
    organisation?: LastActiveTenantOrganisationEnum;
}


/**
 * @export
 */
export const LastActiveTenantDiseaseEnum = {
    AtopicDermatitis: 'ATOPIC_DERMATITIS',
    ChronicHandEczema: 'CHRONIC_HAND_ECZEMA',
    ChronicObstructivePulmonaryDisease: 'CHRONIC_OBSTRUCTIVE_PULMONARY_DISEASE',
    ChronicUrticaria: 'CHRONIC_URTICARIA',
    Endometriosis: 'ENDOMETRIOSIS',
    LongCovid: 'LONG_COVID',
    MyastheniaGravis: 'MYASTHENIA_GRAVIS',
    MultipleSclerosis: 'MULTIPLE_SCLEROSIS',
    ThyroidEyeDisease: 'THYROID_EYE_DISEASE',
    Opade: 'OPADE'
} as const;
export type LastActiveTenantDiseaseEnum = typeof LastActiveTenantDiseaseEnum[keyof typeof LastActiveTenantDiseaseEnum];

/**
 * @export
 */
export const LastActiveTenantOrganisationEnum = {
    Ailc: 'ailc',
    Dadi: 'dadi',
    Tanalentamente: 'tanalentamente',
    Dermatiteatopicadiary: 'dermatiteatopicadiary',
    MiasteniaItalia: 'miastenia-italia',
    Aendo: 'aendo',
    NoiConVoi: 'noi-con-voi',
    EndoCare: 'endo-care',
    Endometriosevereinigung: 'endometriosevereinigung'
} as const;
export type LastActiveTenantOrganisationEnum = typeof LastActiveTenantOrganisationEnum[keyof typeof LastActiveTenantOrganisationEnum];


/**
 * Check if a given object implements the LastActiveTenant interface.
 */
export function instanceOfLastActiveTenant(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "disease" in value;

    return isInstance;
}

export function LastActiveTenantFromJSON(json: any): LastActiveTenant {
    return LastActiveTenantFromJSONTyped(json, false);
}

export function LastActiveTenantFromJSONTyped(json: any, ignoreDiscriminator: boolean): LastActiveTenant {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'disease': json['disease'],
        'organisation': !exists(json, 'organisation') ? undefined : json['organisation'],
    };
}

export function LastActiveTenantToJSON(value?: LastActiveTenant | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'disease': value.disease,
        'organisation': value.organisation,
    };
}

