import { confirmSignUp } from "aws-amplify/auth";
import { useEffect, useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import {
  CognitoClientMetadata,
  ConfirmationCodeLinkContent,
} from "shared/types/auth";
import { ensureRequired } from "shared/utils/utility";

import { cognitoPaths } from "./paths";
import { LoadingIndicator } from "../../../../components/loading-spinner";
import { useTenantId } from "../../../../util/use-active-tenant-id";
import { AuthMessageLevel } from "../../generic/types";
import { useAuthNavigate } from "../../generic/use-auth-navigate";
import { useCognitoConfiguration } from "../hooks/use-cognito-configuration";
import { CognitoError, handleCognitoError } from "../misc/cognito-errors";

export const CognitoConfirmSignUpCallback: React.FC = () => {
  const cognitoConfigurationState = useCognitoConfiguration();

  const [params] = useSearchParams();
  const { sub, confirmation_code, email, lng } = useMemo(() => {
    const keyVals = Object.fromEntries(params.entries());
    return keyVals as unknown as ConfirmationCodeLinkContent;
  }, [params]);

  const navigate = useAuthNavigate(email ? () => ({ email }) : undefined);

  const hasSentRequest = useRef(false);
  const { disease, organisation } = useTenantId();
  const {
    i18n: { language },
  } = useTranslation();

  useEffect(() => {
    try {
      const requiredClientMetadata: CognitoClientMetadata = ensureRequired(
        {
          disease,
          isLocalhost: process.env.NODE_ENV === "development" ? "yes" : "no",
          language: lng ?? language,
          userAgent: navigator.userAgent,
        },
        ["disease", "isLocalhost", "language"],
      );

      if (sub && confirmation_code) {
        if (cognitoConfigurationState === "ready" && !hasSentRequest.current) {
          hasSentRequest.current = true;
          confirmSignUp({
            username: sub,
            confirmationCode: confirmation_code,
            options: {
              clientMetadata: {
                ...requiredClientMetadata,
                ...(organisation ? { organisation } : {}),
              },
            },
          })
            .then(() => {
              navigate({
                to: { type: "uri", uri: cognitoPaths.signIn },
                replace: true,
                state: {
                  message: {
                    level: AuthMessageLevel.INFO,
                    tx: "auth.signUp.registrationConfirmed",
                  },
                },
              });
            })
            .catch(async (err: CognitoError) => {
              navigate({
                to: { type: "uri", uri: cognitoPaths.signIn },
                replace: true,
                state: {
                  message: {
                    level: AuthMessageLevel.ERROR,
                    ...handleCognitoError(err),
                  },
                },
              });
            });
        }
      }
      return;
    } catch {
      /* empty */
    }
    navigate({
      to: { type: "uri", uri: cognitoPaths.signIn },
      replace: true,
      state: {
        message: {
          level: AuthMessageLevel.ERROR,
          tx: "auth.signUp.confirmationLinkError",
        },
      },
    });
  }, [
    cognitoConfigurationState,
    confirmation_code,
    disease,
    language,
    navigate,
    organisation,
    sub,
    lng,
  ]);

  return (
    <div className="min-h-16 flex flex-row items-center justify-center self-stretch">
      <LoadingIndicator
        as="spinner"
        message={{ tx: "auth.signUp.confirmingSignUpWaitingMessage" }}
      />
    </div>
  );
};
