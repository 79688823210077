import { useState } from "react";

import { SubmitFeedback, UserFeedback } from "../../../api/generated/backend";
import { Text } from "../../text";

const MAXIMUM_SCALE_RATING = 10;
export const ScaleUserFeedback: React.FC<{
  feedback: UserFeedback;
  setFeedback: (submitFeedback: SubmitFeedback | undefined) => void;
}> = ({ feedback, setFeedback }) => {
  const [value, setValue] = useState(0);

  return (
    <div className="flex w-full flex-col items-center gap-1">
      <input
        type="range"
        min={0}
        max={10}
        step={1}
        value={value}
        className="range range-primary range-md"
        onChange={(e) => {
          setFeedback({
            ...feedback,
            details: `${e.target.value}/${MAXIMUM_SCALE_RATING}`,
          });
          setValue(Number(e.target.value));
        }}
      />
      <div className="flex w-full justify-between px-2 text-xs">
        {Array.from(new Array(MAXIMUM_SCALE_RATING + 1)).map((_, idx) => (
          <span key={idx}>{idx}</span>
        ))}
      </div>
      <div className="flex w-full justify-between text-xs">
        <Text tx="userFeedback.veryUnlikely" />
        <Text tx="userFeedback.veryLikely" />
      </div>
    </div>
  );
};
