/* tslint:disable */
/* eslint-disable */
/**
 * Conversation Service
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { User } from './User';
import {
    UserFromJSON,
    UserFromJSONTyped,
    UserToJSON,
} from './User';

/**
 * Represents a Report record
 * @export
 * @interface Report
 */
export interface Report {
    /**
     * 
     * @type {string}
     * @memberof Report
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof Report
     */
    conversationId: string;
    /**
     * 
     * @type {string}
     * @memberof Report
     */
    reportContent: string;
    /**
     * 
     * @type {Date}
     * @memberof Report
     */
    createdAt: Date;
    /**
     * 
     * @type {string}
     * @memberof Report
     */
    questionNodeId?: string;
    /**
     * 
     * @type {User}
     * @memberof Report
     */
    user?: User;
    /**
     * 
     * @type {string}
     * @memberof Report
     */
    userId?: string;
}

/**
 * Check if a given object implements the Report interface.
 */
export function instanceOfReport(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "conversationId" in value;
    isInstance = isInstance && "reportContent" in value;
    isInstance = isInstance && "createdAt" in value;

    return isInstance;
}

export function ReportFromJSON(json: any): Report {
    return ReportFromJSONTyped(json, false);
}

export function ReportFromJSONTyped(json: any, ignoreDiscriminator: boolean): Report {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'conversationId': json['conversationId'],
        'reportContent': json['reportContent'],
        'createdAt': (new Date(json['createdAt'])),
        'questionNodeId': !exists(json, 'questionNodeId') ? undefined : json['questionNodeId'],
        'user': !exists(json, 'user') ? undefined : UserFromJSON(json['user']),
        'userId': !exists(json, 'userId') ? undefined : json['userId'],
    };
}

export function ReportToJSON(value?: Report | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'conversationId': value.conversationId,
        'reportContent': value.reportContent,
        'createdAt': (value.createdAt.toISOString()),
        'questionNodeId': value.questionNodeId,
        'user': UserToJSON(value.user),
        'userId': value.userId,
    };
}

