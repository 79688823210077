/* tslint:disable */
/* eslint-disable */
/**
 * Conversation Service
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * An enumeration.
 * @export
 */
export const Subtype = {
    Name: 'NAME',
    PostalCode: 'POSTAL_CODE',
    MarketingChannel: 'MARKETING_CHANNEL',
    Occupation: 'OCCUPATION',
    MigrationBackground: 'MIGRATION_BACKGROUND',
    Email: 'EMAIL',
    Phone: 'PHONE',
    OpadeStudyId: 'OPADE_STUDY_ID',
    YearOfBirth: 'YEAR_OF_BIRTH',
    Introduction: 'INTRODUCTION',
    Country: 'COUNTRY',
    Pag: 'PAG',
    Acknowledge: 'ACKNOWLEDGE',
    Terms: 'TERMS',
    EndoStudyAgreement: 'ENDO_STUDY_AGREEMENT',
    Link: 'LINK',
    Gender: 'GENDER',
    Ethnicity: 'ETHNICITY',
    Education: 'EDUCATION',
    Diagnosis: 'DIAGNOSIS',
    Insurance: 'INSURANCE',
    Final: 'FINAL'
} as const;
export type Subtype = typeof Subtype[keyof typeof Subtype];


export function SubtypeFromJSON(json: any): Subtype {
    return SubtypeFromJSONTyped(json, false);
}

export function SubtypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): Subtype {
    return json as Subtype;
}

export function SubtypeToJSON(value?: Subtype | null): any {
    return value as any;
}

