import React from "react";

import { useT } from "../../../../../../../i18n/use-t";
import { Button } from "../../../../../../form/button";
import { ChatOptionComponentProps } from "../../lookup";

export const Choices: React.FC<ChatOptionComponentProps> = ({
  choices,
  sendResponse,
}) => {
  const t = useT();

  return (
    <div className="flex items-center gap-1">
      {choices.map((choice) => (
        <Button
          key={choice}
          tx={choice}
          onClick={() =>
            sendResponse({ message: t({ tx: choice }), selection: [choice] })
          }
        />
      ))}
    </div>
  );
};
