import { confirmSignIn, signIn } from "aws-amplify/auth";
import { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import { FrontendPageEnum } from "shared/model/pages";
import { CognitoClientMetadata } from "shared/types/auth";

import { cognitoPaths } from "./paths";
import { PlainError } from "../../../../components/events/plain-error";
import { LoadingIndicator } from "../../../../components/loading-spinner";
import { useStore } from "../../../../models/helpers";
import { useNavigateInsideTenant } from "../../../../util/navigation-hooks";
import { useTenantId } from "../../../../util/use-active-tenant-id";
import { AuthMessageLevel } from "../../generic/types";
import { useAuthNavigate } from "../../generic/use-auth-navigate";
import { useCognitoConfiguration } from "../hooks/use-cognito-configuration";
import { CognitoError, handleCognitoError } from "../misc/cognito-errors";

function assertIsMessage(
  msg: unknown,
): asserts msg is { cognitoSub: string; exp: number; iat: number } {
  if (
    !msg ||
    typeof msg !== "object" ||
    !("cognitoSub" in msg) ||
    typeof msg.cognitoSub !== "string" ||
    !("exp" in msg) ||
    typeof msg.exp !== "number" ||
    !("iat" in msg) ||
    typeof msg.iat !== "number"
  ) {
    throw new Error("Invalid magic link");
  }
}

const decodeMagicToken = (token: string) => {
  try {
    const header = token.split(".")[0];
    const message = JSON.parse(atob(header));

    assertIsMessage(message);

    return message.cognitoSub;
  } catch (err) {
    console.log("Error when decoding magic token: " + err);
    throw err;
  }
};

export const CognitoVerifyMagicLink: React.FC = () => {
  const store = useStore();
  const [params] = useSearchParams();
  const { disease, organisation } = useTenantId();
  const {
    i18n: { language },
  } = useTranslation();
  const cognitoConfigurationState = useCognitoConfiguration();

  const clientMetadata: CognitoClientMetadata = useMemo(
    () => ({
      disease,
      ...(organisation ? { organisation } : {}),
      isLocalhost: process.env.NODE_ENV === "development" ? "yes" : "no",
      signInMethod: "MAGIC_LINK",
      alreadyHaveMagicLink: "yes",
      userAgent: navigator.userAgent,
      language,
    }),
    [disease, organisation, language],
  );

  const navigate = useAuthNavigate();
  const tenantNavigate = useNavigateInsideTenant();

  useEffect(() => {
    if (cognitoConfigurationState !== "ready") {
      return;
    }
    const token = params.get("token");

    if (!token) {
      store.addToastEvent(
        new PlainError({ tx: "auth.signInMagic.verifyMagicLinkError" }),
      );
      return;
    }

    const dataToSend = {
      challengeResponse: token,
      options: {
        clientMetadata: clientMetadata as Record<string, string>,
      },
    };

    const username = decodeMagicToken(dataToSend.challengeResponse);

    signIn({
      username,
      options: {
        authFlowType: "CUSTOM_WITHOUT_SRP",
        autoSignIn: false,
        clientMetadata: dataToSend.options.clientMetadata,
      },
    })
      .then(({ isSignedIn, nextStep }) => {
        if (
          !isSignedIn &&
          nextStep.signInStep === "CONFIRM_SIGN_IN_WITH_CUSTOM_CHALLENGE"
        ) {
          confirmSignIn(dataToSend)
            .then(
              ({ isSignedIn: localIsSignedIn, nextStep: localNextStep }) => {
                if (localIsSignedIn) {
                  tenantNavigate(FrontendPageEnum.CHAT);
                } else {
                  console.log("confirmSignIn", localNextStep);
                }
              },
            )
            .catch((err) => {
              console.log("confirmSignIn", err);
              navigate({
                to: {
                  type: "uri",
                  uri: cognitoPaths.loginProblem,
                },
                replace: true,
                state: {
                  message: {
                    level: AuthMessageLevel.ERROR,
                    tx: "auth.loginProblem.outdatedLink",
                  },
                },
              });
            });
        }
      })
      .catch((err: CognitoError) => {
        navigate({
          to: {
            type: "current_url",
          },
          replace: true,
          state: {
            message: {
              level: AuthMessageLevel.ERROR,
              ...handleCognitoError(err),
            },
          },
        });
      });
  }, [
    clientMetadata,
    cognitoConfigurationState,
    navigate,
    tenantNavigate,
    params,
    store,
  ]);

  return (
    <div className="min-h-16 flex flex-row items-center justify-center self-stretch">
      <LoadingIndicator
        as="spinner"
        message={{ tx: "auth.signUp.confirmingSignUpWaitingMessage" }}
      />
    </div>
  );
};
