import { signIn } from "aws-amplify/auth";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { CognitoClientMetadata } from "shared/types/auth";
import { tx } from "shared/types/i18n";

import { cognitoPaths } from "./paths";
import { SecondaryButton } from "../../../../components/form/button";
import { Form } from "../../../../components/form/form";
import { FormTextInput } from "../../../../components/form/form-text-input";
import { LoadingButton } from "../../../../components/form/loading-button";
import { Link } from "../../../../components/link";
import { Text } from "../../../../components/text";
import { isEmailRegex } from "../../../../util/regex";
import { useTenantId } from "../../../../util/use-active-tenant-id";
import { AuthMessageLevel } from "../../generic/types";
import { useAuthNavigate } from "../../generic/use-auth-navigate";
import { useAuthNavigateState } from "../../generic/use-auth-navigate-state";
import { handleCognitoError, isCognitoError } from "../misc/cognito-errors";

export const CognitoSignIn: React.FC = () => {
  const tenantId = useTenantId();

  const { prefillValues } = useAuthNavigateState();
  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors, isSubmitting },
  } = useForm<{ email: string; password: string }>({
    defaultValues: prefillValues,
  });
  const navigate = useAuthNavigate(
    () => ({
      email: getValues("email"),
    }),
    [getValues],
  );
  const { disease, organisation } = useTenantId();
  const {
    i18n: { language },
  } = useTranslation();

  const onSubmitTryLogin = handleSubmit(async ({ email, password }) => {
    const clientMetadata: CognitoClientMetadata = {
      disease,
      ...(organisation ? { organisation } : {}),
      isLocalhost: process.env.NODE_ENV === "development" ? "yes" : "no",
      userAgent: navigator.userAgent,
      language,
    };
    try {
      const { isSignedIn, nextStep } = await signIn({
        username: email,
        password,
        options: { clientMetadata: clientMetadata as Record<string, string> },
      });

      if (isSignedIn) {
        navigate({ to: { type: "redirect_url" }, replace: true });
      } else if (
        nextStep.signInStep === "CONFIRM_SIGN_IN_WITH_NEW_PASSWORD_REQUIRED"
      ) {
        navigate({
          to: { type: "uri", uri: cognitoPaths.needToMigrateAccountNotice },
        });
      } else {
        navigate({
          to: { type: "uri", uri: cognitoPaths.signIn },
          replace: true,
          state: {
            message: {
              level: AuthMessageLevel.ERROR,
              tx: "auth.signIn.errors.unexpectedSignInChallenge",
              txData: {},
            },
          },
        });
      }
    } catch (err: unknown) {
      if (isCognitoError(err)) {
        if (err.name === "UserNotConfirmedException") {
          // Note: this needs to be re-added with the new Amplify version
        } else {
          navigate({
            to: { type: "uri", uri: cognitoPaths.signIn },
            replace: true,
            state: {
              message: {
                level: AuthMessageLevel.ERROR,
                ...handleCognitoError(err),
              },
            },
          });
        }
      } else {
        navigate({
          to: { type: "uri", uri: cognitoPaths.signIn },
          replace: true,
          state: {
            message: {
              level: AuthMessageLevel.ERROR,
              txUnchecked: "auth.signIn.errors.UnknownError",
            },
          },
        });
      }
    }
  });

  return (
    <>
      {/* <CognitoSocialButtons /> */}
      <Form onSubmit={onSubmitTryLogin}>
        <FormTextInput
          title={{ tx: "auth.signIn.inputs.email.title" }}
          placeholder={{ tx: "auth.signIn.inputs.email.placeholder" }}
          autoComplete="username"
          {...register("email", {
            required: tx("auth.signIn.inputs.email.fieldMissingError"),
            pattern: {
              value: isEmailRegex,
              message: tx("auth.signIn.inputs.email.notAnEmailError"),
            },
          })}
          error={{ txUnchecked: errors.email?.message }}
        />
        <FormTextInput
          title={{ tx: "auth.signIn.inputs.password.title" }}
          placeholder={{ tx: "auth.signIn.inputs.password.placeholder" }}
          type="password"
          autoComplete="current-password"
          {...register("password", {
            required: tx("auth.signIn.inputs.password.fieldMissingError"),
          })}
          error={{ txUnchecked: errors.password?.message }}
        />
        <LoadingButton
          type="submit"
          Button={SecondaryButton}
          loading={isSubmitting}
          isDisabled={isSubmitting}
          tx="auth.signIn.signInButton"
        />
      </Form>
      <div>
        <Text
          as="p"
          tx="auth.navigation.signInWithMagicLink"
          txComponents={{
            SignInWithMagicLink: (
              <Link
                onClick={() =>
                  navigate({
                    to: { type: "uri", uri: cognitoPaths.magicSignIn },
                  })
                }
              />
            ),
          }}
        />
        <Text
          as="p"
          tx="auth.navigation.resetPassword"
          txComponents={{
            ResetPasswordLink: (
              <Link
                onClick={() =>
                  navigate({
                    to: { type: "uri", uri: cognitoPaths.resetPassword },
                  })
                }
              />
            ),
          }}
        />
        {!tenantId.organisation && (
          <Text
            as="p"
            tx="auth.navigation.signUpInstead"
            txComponents={{
              SignUpLink: (
                <Link
                  onClick={() =>
                    navigate({
                      to: { type: "uri", uri: cognitoPaths.onboarding },
                    })
                  }
                />
              ),
            }}
          />
        )}
      </div>
    </>
  );
};
